@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;1,300&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700;1,800;1,900&display=swap");
.App {
  text-align: center;
  letter-spacing: 1px;
  font-weight: 500;
}
body {
  font-family: "Work Sans";
  font-weight: 400;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
p {
  font-size: 14px !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.slider {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* &.previousButton {
      left: 0px;
    }

    &.nextButton {
      right: 0px;
    } */
}

.leftButton {
  background-color: rgb(55, 68, 73);
  bottom: 0;
  left: 0;
  /* z-index: 10000; */
  height: 60px;
  width: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  /* z-index: 16; */
  font-family: Roboto;
  cursor: pointer;
  color: rgb(255, 255, 255);
  text-decoration: none;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: center;
  font-size: 17px;
  filter: none;
  transform-origin: 50% 50%;
  transform: translate(0px, 0px);
  opacity: 1;
  visibility: visible;
  border-width: 0px;
  border-radius: 0px;
}

.rightButton {
  background-color: rgb(30, 45, 50);
  /* z-index: 10000; */
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  font-size: 32px;
  /* z-index: 16; */
  font-family: Roboto;
  text-transform: uppercase;
  cursor: pointer;
  color: rgb(255, 255, 255);
  text-decoration: none;
  white-space: normal;
  width: 60px;
  height: 60px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: center;
  line-height: 51px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 17px;
  filter: none;
  transform-origin: 50% 50%;
  transform: translate(0px, 0px);
  opacity: 1;
  visibility: visible;
  border-width: 0px;
  border-radius: 0px;
}

.rightButton:hover {
  background-color: rgb(219, 176, 133);
  transition: 0.75s ease-in-out;
  color: rgb(0, 0, 0);
}

.leftButton:hover {
  background-color: rgb(219, 176, 133);
  transition: 0.75s;
  color: rgb(0, 0, 0);
}

.activeicon:hover {
}
@keyframes fadeIn {
  0% {
    /* opacity: 0; */
    background-color: rgb(0, 0, 0);
  }
  100% {
    /* opacity: 1; */
    background-color: rgba(0, 0, 0, 0.65);
  }
}

.imgv {
  /* height: 100vh; */
  width: 100%;
  animation: fadeIn 5s;
  background-position: top right;
  background-attachment: fixed;
  background-size: cover;
}
.sliderImgContainer {
  height: 100vh;
  overflow: hidden;
}
@keyframes leftToRight {
  0% {
    left: 0;
  }
  100% {
    left: 40%;
  }
}
.bottom-block {
  background-color: rgb(8, 21, 26);
  height: 120px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
  width: 100%;
  align-items: center;
  display: flex;
  animation: leftToRight 1s;
}

.icon {
  height: 12;
  font-size: 12;
}
.backgroung-drop {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.65);
  animation: fadeIn 2s;
}

.fade-in {
  /* height: 150px;
  width: 1px;
  opacity: 1; */
  transition: all 0.75s ease;
}

.activeNumber {
  /* z-index: 7; */
  font-family: "Playfair Display";
  display: inline-block;
  height: auto;
  width: auto;
  color: rgb(227, 174, 126);
  text-align: left;
  font-weight: 400;
  font-size: 70px;
  filter: none;
  font-weight: 600;
  transform-origin: 50% 50%;
  transform: translate(0px, 0px);
  opacity: 1;
  padding-bottom: 2.5%;
}

.content-slider {
  /* z-index: 8; */
  position: relative;
  width: 70%;
  font-family: "Open Sans", sans-serif;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  font-size: 14px;
  padding-left: 6%;
  padding-right: 2%;
  transform-origin: 50% 50%;
  transform: translate(0px, 0px);
  opacity: 1;
}

.content-block {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.logo-icon {
  width: 115px;
  /* margin-left: 2%; */
}

.nav-icon-container {
  display: none;
}

.nav-button {
  font-family: "Open Sans";
  font-weight: 800;
  font-size: 15px;
  color: white;
  line-height: 123.6%;
  margin-right: 40px;
}

.nav-button-solid {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: white;
  background-color: orange;
  line-height: 123.6%;
  margin-right: 40px;
}

.nav-button-icon {
  font-family: "Open Sans", sans-serif;
  color: white;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  background-color: orange;
  margin-right: 40px;
  height: 32px;
  width: 32px;
  position: absolute;
  right: 0px;
}
.nav-button-icon:hover {
  background-color: transparent;
  color: orange;
  border: 1px solid orange;
}
.vertical_line {
  height: 22vh;
  width: 2px;
  background-color: rgba(227, 175, 126, 0.704);
  position: absolute;
  right: 40px;
  bottom: 40px;
  z-index: 3;
}
.nav-button:hover {
  color: orange;
}

.vertical_line_text {
  position: absolute;
  right: 15px;
  font-family: "Open Sans";
  font-weight: 900;
  text-transform: uppercase;
  font-size: 10px;
  bottom: 50vh;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.44);
  z-index: 1;
  transform: rotate(270deg);
}

.vertical_line_text :hover {
  cursor: url("./assets/scroll.svg"), auto;
}

.container_base {
  /* background-color: rgb(8, 21, 26); */
  height: 100vh;
  width: 100%;
}
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.laptop {
  display: block;
}
.mobile {
  display: none;
}
.SliderTitle {
  position: absolute;
  top: 40vh;
  color: white;
  z-index: 8;
  /* text-align: center; */
  font-size: 72px;
  font-weight: bold;
  left: 200px;
  width: 90%;
}
@media only screen and (min-width: 768px) {
  .bottom-block {
    width: 60%;
  }
  .nav-i0con-container {
    display: flex;
    align-items: center;
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 10));
  }
}
.slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 20);
  height: 150px;
}
.slide-track:hover {
  animation-play-state: paused;
}
@media only screen and (max-width: 768px) {
  .backgroung-drop {
    height: 90vh;
  }
  .sliderImgContainer {
    height: 80vh;
  }
  .slide-track:hover {
    animation-play-state: running;
  }
  .activeNumber {
    bottom: 20vh;
    font-size: 50px;
    position: relative;
    left: 45px;
    z-index: 8;
  }
  .serviceForMobile {
    color: white !important;
    margin-top: -60px;
  }
  span {
    color: #dc691d;
  }
  .leftButton {
    width: 50px;
  }
  .rightButton {
    width: 50px;
  }
  .SliderTitle {
    position: absolute;
    bottom: 35vh;
    top: auto;
    color: white;
    z-index: 8;
    /* text-align: center; */
    left: 50px;
    width: 80%;
  }
  .SliderTitle h1 {
    font-size: 40px;
    font-weight: bold;
  }
  .SliderTitle p {
    font-weight: 400;
  }
  .content-slider {
    font-size: 12px;
    width: 100%;
  }
  .laptop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .vertical_line {
    height: 13vh;
    bottom: 100px;
  }
  .bottom-block {
    animation: none;
    /* bottom: 20px; */
  }
  .imgv {
    height: auto;
    width: 100%;
  }
  .slider {
    height: 90vh;
    overflow: hidden;
  }
}
.react-multi-carousel-track {
  padding: 50px 0 !important;
  padding-top: 0px !important;
}
.react-multi-carousel-dot--active button {
  background: #dc691d !important;
  width: 30px !important;
  border-radius: 10px !important;
}

.react-multi-carousel-dot button {
  border-color: rgb(240, 240, 240) !important;
  border-width: 1px !important;
}
/* //mobile view css */

